<!-- AdvertiseClueDetail.vue -->
<template>

  <div class="bg-gray-100 h-screen">
    <div class="mx-auto px-3 pt-3 pb-8">
      <!-- Status bar -->
      <div class="flex items-center px-3 py-3 bg-white">
        <div class="ml-1 text-gray-800">项 目：</div>
        <div class="text-red-500 text-sm">{{ adClueDetail.promotionName }}</div>
      </div>
      <!-- List items -->
      <div class="mt-1 mx-auto px-1 bg-white divide-y">
        <div class="flex items-center px-2 py-3">
          <i class="fas fa-infinity text-orange-500"></i>
          <span class="ml-1 text-gray-800">客户名：</span>
          <div class="text-gray-800 text-sm">{{ adClueDetail.name }}</div>
        </div>
        <div class="flex items-center px-2 py-3">
          <i class="fas fa-shake text-blue-500"></i>
          <span class="ml-1 text-gray-800">状  态：</span>
          <div class="text-gray-800 text-sm">{{ adClueDetail.clueStateName }}</div>
        </div>
        <div class="flex items-center px-2 py-3">
          <i class="fas fa-sun text-yellow-500"></i>
          <span class="ml-1 text-gray-800">电  话：</span>
          <div class="text-gray-800 text-sm">{{ adClueDetail.telephone }}</div>
          <button class="text-xs ml-auto px-3"
                  v-if="adClueDetail.telephone !== null && adClueDetail.telephone !== undefined && adClueDetail.telephone !== ''"
                  @click="copyAndCall(adClueDetail.telephone)">呼叫
          </button>
        </div>
        <div class="flex items-center px-2 py-3">
          <i class="fas fa-crosshairs text-red-500"></i>
          <span class="ml-1 text-gray-800">微  信：</span>
          <div class="text-gray-800 text-sm">{{ adClueDetail.weixin }}</div>
          <button class="text-xs ml-auto px-3"
                  v-if="adClueDetail.weixin !== null && adClueDetail.weixin !== undefined && adClueDetail.weixin !== ''"
                  @click="copyToClipboard(adClueDetail.weixin)">复制
          </button>
        </div>
        <div class="flex items-center px-2 py-3">
          <i class="fas fa-location-arrow text-blue-500"></i>
          <span class="ml-1 text-gray-800">Q  Q：</span>
          <div class="text-gray-800 text-sm">{{ adClueDetail.qq }}</div>
          <button class="text-xs ml-auto px-3"
                  v-if="adClueDetail.qq !== null && adClueDetail.qq !== undefined && adClueDetail.qq !== ''"
                  @click="copyToClipboard(adClueDetail.qq)">复制
          </button>
        </div>
        <div class="flex items-center px-2 py-3">
          <i class="fas fa-shopping-basket text-red-500"></i>
          <span class="ml-1 text-gray-800">定  位：</span>
          <div class="text-gray-800 text-sm"><span class="flex w-40">{{ adClueDetail.location }}</span></div>
          <button class="text-xs ml-auto px-3"
                  v-if="adClueDetail.address !== null && adClueDetail.address !== undefined && adClueDetail.address !== ''"
                  @click="copyToClipboard(adClueDetail.address)">复制
          </button>
        </div>
        <div class="flex items-center px-2 py-3">
          <span class="ml-1 text-gray-800">来  源：</span>
          <div class="text-gray-800 text-sm">{{ adClueDetail.appName }}</div>
        </div>
        <div class="flex items-center px-2 py-3">
          <i class="fas fa-gamepad text-blue-500"></i>
          <span class="ml-1 text-gray-800">时 间：</span>
          <div class="text-gray-800 text-sm">{{ adClueDetail.createTime }}</div>
        </div>
      </div>
      <!-- Bottom bar -->
      <div class="flex items-center px-3 py-3 bg-white mt-1">
        <div class="ml-1 text-gray-800">留 言：</div>
        <div class="text-red-800 text-sm">{{ adClueDetail.remark }}</div>
      </div>
    </div>
  </div>

</template>

<script>

import {getAdvertiseClue} from "@/apis/advertiseClue";

export default {
  data() {
    return {
      adClueDetail: {
        promotionName: '您无权限查看',
        title: '',
        description: '',
        appName: '',
        name: '',
        gender: '',
        clueStateName: '',
        telephone: '',
        weixin: '',
        qq: '',
        location: '',
        remark: '',
        createTime: '',
      },
    };
  },
  methods: {
    fetchAdDetails() {
      // 根据路由参数获取广告线索的 ID
      const id = this.$route.params.id;
      const user = this.$route.params.user;
      setTimeout(getAdvertiseClue(id, user).then(({data: clue}) => {
        const clueInfo = clue.data;
        if (clueInfo) {
          let promotionName = clueInfo.promotionName;
          let partsArray = promotionName.split("_广告")
          promotionName = partsArray[0]

          this.adClueDetail = {
            promotionName: promotionName || '',
            appName: clueInfo.appName || '',
            name: clueInfo.name || '',
            clueStateName: clueInfo.clueStateName || '',
            telephone: clueInfo.telephone || '',
            weixin: clueInfo.weixin || '',
            qq: clueInfo.qq || '',
            location: clueInfo.location || '',
            remark: clueInfo.remark || '',
            createTime: clueInfo.createTimeDetail || '',
          };
        }
      }));
    },
    copyToClipboard(value) {
      const el = document.createElement('textarea');
      el.value = value;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      // You may show a notification or provide feedback to the user
      alert('已复制到剪切板');
    },
    copyAndCall(value) {
      // 创建一个临时文本区域
      var tempTextarea = document.createElement("textarea");
      // 将电话号码设置为文本区域的值
      tempTextarea.value = value;
      // 将文本区域添加到文档中
      document.body.appendChild(tempTextarea);
      // 选择文本
      tempTextarea.select();
      tempTextarea.setSelectionRange(0, 99999); /* For mobile devices */
      // 复制文本
      document.execCommand("copy");
      // 从文档中移除文本区域
      document.body.removeChild(tempTextarea);
      // 调用手机的通话功能
      window.location.href = "tel:" + value;
    }
  },
  mounted() {
    // 在组件挂载时调用 fetchAdDetails 方法
    this.fetchAdDetails();
  },
  watch: {
    // 监听路由参数的变化，重新调用 fetchAdDetails 方法
    '$route.params.id': 'fetchAdDetails',
  },
};
</script>