// main.js
import {createApp} from 'vue';
import App from './App.vue';
import {createRouter, createWebHistory} from 'vue-router';
import AdvertiseClueDetail from './components/AdvertiseClueDetail.vue';
import HelloWorld from "@/components/HelloWorld";

const routes = [
    {path: '/', component: HelloWorld, meta: { title: 'Home' } },
    {path: '/advertise/clue/:id/:user', component: AdvertiseClueDetail, meta: { title: '广告线索预览' } },
    // 添加其他路由规则
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    // 在路由切换前设置页面标题
    document.title = to.meta.title || 'Default Title';
    next();
});

const app = createApp(App);
app.use(router);
app.mount('#app');
